/* lato font */
@import url('https://fonts.googleapis.com/css2?family=Architects+Daughter&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Megrim&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
/* Heebo font  */
@import url('https://fonts.googleapis.com/css2?family=Architects+Daughter&family=Heebo:wght@100..900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Megrim&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
/* Cookie */
@import url('https://fonts.googleapis.com/css2?family=Architects+Daughter&family=Cookie&family=Heebo:wght@100..900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Megrim&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
/* Fuggles */
@import url('https://fonts.googleapis.com/css2?family=Architects+Daughter&family=Cookie&family=Fuggles&family=Heebo:wght@100..900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Megrim&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
@font-face {
  font-family: 'Kiona';
  src: url('/public/fonts/Kiona-Regular.ttf');
}
@font-face {
  font-family: 'Heimdal';
  src: url('/public/fonts/Heimdal.ttf');
}
@font-face {
  font-family: 'Kostania-Bold';
  src: url('/public/fonts/Kostania-Bold.ttf');
}

*{
  margin: 0 0;
  padding: 0 0;
}
body{
  background-color: #f8f8f8;
}
.header .right_header .logo{
 
}
.header {
  /* background-color: #00000082; */
  padding: 15px 0;
  z-index: 100;
  /* padding: 10px ; */
  color: #fff;
  /* position: fixed; */
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* background-color: #0000008f; */
  /* background-color: #000000af; */
  background-color: #0000007d;
  gap: 50px;
  /* background-color: #000000af; */
  transition: .2s ease-in;
}
.header ul{
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  gap: 30px;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  font-size: 14px;
  font-family: 'Lato',sans-serif;
}
.header ul a{
  text-decoration: none;
  color: #f8f8f8;
}
.header_social{
  display: flex;
  align-items: center;
  justify-content: center;
}
.header_social img{
  width: 20px;
  filter: invert(1);
  opacity: .9;
}
.header_social ul{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px !important;
}
.humberger_menu{
  display: none;
}
.main{
  height: 100vh;
  width: 100%;
  background-position: center;
  background-size:cover;
  background-repeat: no-repeat;
  background-color: #000000;
  position: relative;
}
.main img{
  object-fit: cover;
  height: 100vh;
  width: 100%;
  opacity: .4 ;
}
.main .content{
  position: absolute;
  bottom: 10%;
  left: 10%;
  color: #fff;
}
.main .content h1{
  font-size: 55px;
  font-family: "Kiona", sans-serif;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.main .content h6{
  font-size: 16px;
  text-transform: capitalize;
  width: 600px;
  color: #efefef;
  font-weight: 200 !important;
  padding: 0px 60px 0 0 ;
  line-height: 1.5em;
  margin: 15px 0;
  font-family: "Heebo", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
}

.touch_btn{
  border: 1px solid #fff;
  margin: 10px 0 0;
  display: inline-block;
  padding: 15px 40px;
  font-size: 14px;
  font-weight: 400;
  font-family: "Heebo", sans-serif;
  text-decoration: none;
  color: #fff;
  text-transform: uppercase;
  transition: .3s;

}
.touch_btn:hover{
  background-color: #fff;
  color: #000000;
}

.main_about{
  background-color: #ffffff;
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5rem 0;
}
.main_about img{
  width: 550px;
  height: 700px;
  object-fit: cover;
}
.main_about .main_about_right{
  width: 550px;
  min-height: 700px;
}
.main_about .main_about_right .content{
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
  padding: 60px;
  margin: 3rem 0 0 -2rem;
  background-color: #fff;
}
.main_about .main_about_right .content h6{
  font-size: 18px;
  font-family: "Lato", sans-serif;
  color: #bc9565;
  text-transform: uppercase; 
  padding: 10px 0;
  letter-spacing: 2px;
}
.main_about .main_about_right .content h1{
  font-size: 40px;
  font-family: "Lato", sans-serif;
  color: #181818;
  text-transform: uppercase; 
  padding: 10px 0;
  letter-spacing: 2px;
}
.main_about .main_about_right .content p{
  font-size: 14px;
  font-family: "Heebo", sans-serif;
  color: #666666;
  text-transform: capitalize; 
  padding: 10px 0;
  line-height: 26px;
}
.call_now{
  border: 1px solid #000;
  margin: 10px 0 0;
  display: inline-block;
  padding: 15px 40px;
  font-size: 14px;
  font-weight: 400;
  font-family: "Heebo", sans-serif;
  text-decoration: none;
  color: #fff;
  text-transform: uppercase;
  transition: .3s;
  background-color: #000;
}

.black_white_btn{
  border: 1px solid #000;
  /* margin: 10px 0 0; */
  display: inline-block;
  padding: 15px 40px;
  font-size: 14px;
  font-weight: 400;
  font-family: "Heebo", sans-serif;
  text-decoration: none;
  color: #fff;
  text-transform: uppercase;
  transition: .3s;
  background-color: #000;
}
.black_white_btn:hover, .call_now:hover{
  background-color: #fff;
  color: #181818;
}

.some_count{
  margin: 40px 20px;
  display: flex;
  width: 90%;
  align-items: center;
  justify-content: space-around;
  text-align: center;
}
.some_count .count_item h1{

  font-family: "Lato", sans-serif;
  font-size: 32px;
  letter-spacing: 2px;
  
}
.some_count .count_item p{

  font-family: "Heebo", sans-serif;
  font-size: 14px;
  letter-spacing: 2px;
  color: #666666;
  margin: 5px 0;

}
.main_service{
  padding:7rem 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap:  30px;
  background-color: #f8f8f8;
}
.main_service .main_service_item{
  width: 350px;
  height: 270px;
  /* padding: 30px 0 30px; */
  box-sizing: border-box;
  background-color: #fff;
  overflow: hidden;
  transition: .3s;
  position: relative;
}
.main_service .main_service_item:hover{
  background-color: #181818;
}

.main_service .main_service_item .content{
  padding: 30px 0 30px 30px;
  position: absolute;
  top: 0px;
}
#main_content_p{
  color: #666666 !important;
  letter-spacing: 2px;

}
.main_service .main_service_item h1{
  font-size: 32px;
  color: #181818;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-family: "Lato", sans-serif;
}
.main_service .main_service_item p{
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  color: #666666;
  margin: 10px 0;

}
.main_service .main_service_item .background_image{
    width: 350px;
    height:270px;
    opacity: 0;
    margin: 0;
    transform: translateY(10px);
    transition: .2s;
}
.main_service .main_service_item .content h2{
  font-size: 24px;
  color: #181818;
  text-transform: capitalize;
  margin: 15px 0;
  letter-spacing: 2px;
  font-family: "Lato", sans-serif;
}
.main_service .main_service_item:hover .background_image{
  opacity: 0.4;
  transform: translateY(0px);
}
.main_service .main_service_item:hover .content h2{
  color: #fff !important;
}
.main_service .main_service_item:hover .content p{
  color: #fff !important;
}
.main_service .main_service_item:hover .content .service_icon{
  filter: invert(1);
}
.container_grid{
  padding: 5rem 5rem 3rem;
  background-color: #fff ;
}
.container_grid h1{
  text-transform: capitalize;
  text-align: center;
  margin: 0 0 80px 0;
  font-family: "Lato", sans-serif;
  font-size: 45px;
  font-weight: 900;

}
.container_grid .content img{
  height: 70px;
  width: 70px;
}
.container_grid .content p{
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  color: #666666;
  margin: 10px;
  text-transform: capitalize;
}
.container_grid .content{
  margin-bottom: 30px;
  
}
.pooja_room{
  filter: grayscale(100);
}
.custom_main_container{
  padding: 2rem 0 0;
  background-color: #f8f8f8;

}
.custom{
  padding: 0 0 5rem ;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.custom_name{
  text-align: center;
  margin: 20px 50px 50px;
  text-transform: capitalize;
  /* text-align: center; */
  /* margin: 0 0 80px 0; */
  font-family: "Lato", sans-serif;
  font-size: 45px;
  font-weight: 900;
}
.custom_container {  display: grid;
  grid-template-columns:  33% 33% 32%;  /* 3 equal columns */
  grid-template-rows: 400px 400px; 
  gap: 10px;
  grid-auto-flow: row;
  grid-template-areas:
    "kitchen kitchen dining"
    "bedroom living living"
    ". . .";

}

.custom_container .kitchen { grid-area: kitchen; }

.custom_container .bedroom { grid-area: bedroom; }

.custom_container .living { grid-area: living; }

.custom_container.dining { grid-area: dining; }

.custom_container .content img{
  width: 100%;
  height: 100%;
  object-fit: cover; /* Adjust if maintaining aspect ratio is critical */
  opacity: .5;
  transition: .3s

}
.custom_container .content:hover img{
  scale: 1.2;
  opacity: .7;
  transform: rotate(3deg);

}
.custom_container .content{
  overflow: hidden;
  background-color: #181818;
  position: relative;
}
.custom_container .content h2{
  position: absolute;
  /* top: 0; */
  color: #fff;
  text-transform: uppercase;
  font-size: 60px;
  font-family: "Lato", sans-serif;
  font-weight: 900;
  letter-spacing: 2px;
  bottom: 0;
  left: 5%;
  /* transform: translate(-50%, 0); */
}
.why_us_container_head{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.why_us_container {
  padding: 3rem;
}
.why_us_container .why_us_container_head p{
  text-align: center;
  width: 500px; 
  
} 
.why_us_container h1{
  text-align: center;
  margin-bottom: 30px;
  text-transform: capitalize;
  /* text-align: center; */
  /* margin: 0 0 80px 0; */
  font-family: "Lato", sans-serif;
  font-size: 45px;
  font-weight: 900;
}
.why_us_container p{
  text-align: center;
  /* width: 500px;  */
  
} 
.why_us_container .reason{
  display: flex;
  gap: 3rem;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.why_us_container .reason .reason_child{
  width: 400px;
}
.why_us_container .reason h2{
  text-align: center;
  text-transform: capitalize;
  font-family: "Lato", sans-serif;
  font-size: 100px;
  font-weight: 900;
  text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.782);
  color: #fff;
}
.why_us_container .reason h6{
  text-align: center;
  text-transform: capitalize;
  font-family: "Lato", sans-serif;
  font-size: 30px;
  font-weight: 500;
  
}

.testimonial{
  width: 100%;
  min-height: 100vh;
  padding: 5rem 10rem;
  background-color: #f8f8f8;
}
.testimonial .testimonial_container{
  display: flex;
  justify-content: center; 
  border-radius: 20px;
  overflow: hidden;
  min-height: 80vh;

}
.client_words{
  padding: 30px;
  background-color: #fff;
  border-radius: 0 10px 10px 0; 
  margin-left: -30px;
  position: relative;

}
.testimonial .testimonial_container .testimonial_left{
  width: 50%;
  background-color: #dcdcdc8f;
  padding: 3rem;
  background-image: url(/public/images/feedback.jpg);
  background-position: center;
  background-size: cover;
  color: #f8f8f8;
  position: relative;
}
.testimonial .testimonial_container .testimonial_left h1{
 margin: 50px 0 0 0;
 text-transform: uppercase;
 font-family: "Lato", sans-serif;
 font-size: 40px;
 letter-spacing: 2px;
 font-weight: 900;

}
.testimonial .testimonial_container .testimonial_left p{
 margin: 30px 0 0 0;
 text-transform: capitalize;
 font-size: 16px;
 margin-right: 40px;
 letter-spacing: 1px;
 font-family: "Heebo", sans-serif;


}
.testimonial .testimonial_container .testimonial_right{
  width: 50%;
  padding: 50px 50px 50px 0;
  background-color: #efefef;
  position: relative;
}
.testimonial .testimonial_container .testimonial_right .icon{
  position: absolute;
  top:20px;
  right: 30px;
  z-index: 1;
  width: 80px;
  opacity: .1;

}
.testimonial .testimonial_container .testimonial_right .content{
 padding: 5rem 2rem;
 height: 500px;
}
.testimonial .testimonial_container .testimonial_right .content h3{
 margin: 20px 0 ;
 text-transform: uppercase;
 font-family: "Lato", sans-serif;
 font-size: 25px;
 letter-spacing: 2px;
 font-weight: 700;
}
.testimonial .testimonial_container .testimonial_right .content p{
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  text-transform: capitalize;
}
.carousel {
  height: 100%;
}
.carousel-indicators [data-bs-target]{
  background-color: #000 !important; /* Change indicator color */
}

.carousel-indicators .active {
  background-color: #fff; /* Change active indicator color */
}
.carousel-control-prev, .carousel-control-next{
  display: none !important;
}
.footer_container_main{
  display: flex;
  gap: 50px;
  position: relative;
  overflow: hidden;
  background-color: #181818;
  width: 100%;

  padding: 2rem 5rem;
}
.logo_image_footer{
  margin: 10px;
}
.getintouch{
  width: 350px;
}
.getintouch h1 {
  color: #fff;
  margin: 10px 0 15px;
}
.getintouch h3{
  color: #fff;
  margin: 10px 0 15px;
}
.getintouch p {
  color: #f8f8f8;
  width: 500px;
}
.getintouch a {
  color: #fff;
  text-decoration: none;
}
.getintouch a:hover {
  color: #595656;
}
.getintouch ul{
  list-style: none;
  margin: 0;
  padding: 0;
}
.getintouch ul li{
  list-style: none;
  margin: 5px 0;
  padding: 0 0 5px 0;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 0 10px;
}
.getintouch ul li p{
  margin: 0;
}
.getintouch ul img{
 width: 20px;
 filter: invert(1);
}
.social a img{
  width: 35px;
  filter: invert(1) brightness(.9);

}
.social{
  display: flex;
  justify-content: baseline;
  align-items: center;
  margin: 15px 0;
  gap: 15px; 
}
.blur{
  width: 200px;
  height: 200px;
  background-color: #fff;
  position: absolute;
  top: -30%;
  right: 20%;
  filter: blur(160px);  
}
.faq_title{
  text-align: center;
    margin-bottom: 30px;
    text-transform: capitalize;
    margin: 0 0 40px 0;
    font-family: "Lato", sans-serif;
    font-size: 30px;
    font-weight: 700;
}
.FAQ_container{
  padding: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.FAQ_container .content{
  width: 75%;
  margin-bottom: 2rem;
}
.accordion-item{
  border-top: none !important;
  border-left: none !important;
  border-right: none !important; 
  margin-bottom: 10px; 
}

.accordion-item .accordion-header button{
  padding: 15px 15px 15px 0;
  font-size: 18px;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  color: #58595b;
  letter-spacing: 1px;
}
.accordion-button:focus{
  border: none;
  box-shadow: none !important;
}
.accordion-button:not(.collapsed) {
  background-color: #fff !important;
}

/* ABOUT US PAGE */

.about_us_main{
  min-height: 55vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 60px ;
  background-color: #fff;
  background-image: linear-gradient(#E5E5E5 0.5px, transparent 0.5px), linear-gradient(to right, #E5E5E5 0.5px, transparent 0.5px);
	background-size: 50px 50px;
  position: relative;
  overflow: hidden;
}
.about_us_main h1{
  font-size: 80px;
  font-family: "Lato", sans-serif;
  font-weight: 900;
}
.about_us_main p{
  font-size: 15px;
  font-family: "Heebo", sans-serif;
  font-weight: 500;
  color: #666666;
}
.about_us_main a{
  color: #000;
  text-decoration: none;
  transition: .2s;
}
.about_us_main a:hover{
  color: #00000052;
  text-decoration: underline;
}
.blur_1{
  height: 350px;
  width: 350px;
  background-color: #0000006e;
  position: absolute;
  top: 70%;
  right: -7%;
  filter: blur(200px);
}
.blur_2{
  height: 350px; 
  width: 350px;
  background-color: #000000af;
  position: absolute;
  bottom: 50%;
  left: -7%;
  top: initial;
  right: initial;
  filter: blur(200px);
}
.about_door{
  width: 100%;
  height: 400px;
  padding: 0 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8f8f8;
}
.about_door_left{
  height: 100%;
  width: 50%;
  padding: 5rem;
}
.about_door_left h1{
  font-size: 30px;
  font-family: "Lato", sans-serif;
  font-weight: 900;
  margin-bottom: 15px;
  letter-spacing: 2px;
}
.about_door_left h1 .since{
  font-size: 15px;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  text-transform: capitalize;
  font-style: italic;
}
.about_door_left p{
  font-size: 16px;
  font-family: "Heebo", sans-serif;
  font-weight: 300;
  color: #666666;
  text-transform: capitalize;
  font-style: italic;
}
.about_door_right{
  background-image: url(/public/images/door_banner.webp);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
  height: 100%;
  width: 50%;
}

/* Testimonial page */
.Testimonial_main_container{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 5rem;
}
.Testimonial_main_container .title{
  font-size: 15px;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  margin: 0;
  letter-spacing: 2px;
}
.Testimonial_main_container .sub_title{
  font-size: 50px;
  font-family: "Lato", sans-serif;
  font-weight: 900;
  margin: 0;
  padding: 0;
  margin: 10px;
  letter-spacing: 2px;
}
.Testimonial_main_container .des{
  font-size: 14px;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  margin: 0;
  padding: 0;
  width: 40%;
  text-align: center;
  margin-bottom: 10px;
  letter-spacing: 2px;
}
.Testimonial_main_container .content {
  padding: 3rem 0;
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;

  
}
.Testimonial_main_container .content .item{
  flex: 0 1 45%;
  border: 1px solid #666666; 
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: .3s;
  border-radius: 30px;
}
.Testimonial_main_container .content .item:hover{
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.Testimonial_main_container .content .item p{
  font-size: 16px;
  font-family: "Heebo", sans-serif;
  font-weight: 300;
  color: #666666;
  text-transform: capitalize;
  font-style: italic;
  letter-spacing: 1px;
}
.Testimonial_main_container .content .item h3{
  font-size: 20px;
  font-family: "Lato", sans-serif;
  font-weight: 900;
  color: #666666;
  text-transform: capitalize;
  font-style: italic;
  letter-spacing: 1px;
}
.star-ratings{
  margin-bottom: 10px;
}

/* contact us page */
.contact_us_main_container{
  padding: 6rem 10rem;
  width: 100%;
  min-height: 100vh;
  justify-content: space-between;
  display: flex;

}
.contact_us_main_container .contact_us_left{
  width: 50%;
  
}
.contact_us_main_container .contact_us_left img{
 margin: auto; 
 display: block;
 text-align: center;
}
.contact_us_main_container .contact_us_right{
  width: 50%;
}
.contact_us_main_container .contact_us_right h1{
  font-size: 45px;
  letter-spacing: 1px;
  font-weight: 900;
}
.contact_us_main_container .contact_us_right .form_container{
 width: 70%;
}
.ResponseNav{
  display: none;
  position: absolute;
  z-index: 10;
  transform: translateY(-100%);
}
.freeze{
  overflow: hidden;
  height: 100vh;
}